import { SETEDITUSER } from "./actions";

const editUserReducer = (state, action) => {
  switch (action.type) {
    case SETEDITUSER: {
      return {
        ...state,
        user_id: action.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default editUserReducer;
