import { SETAPPLICATION } from "./actions";

const applicationReducer = (state, action) => {
  switch (action.type) {
    case SETAPPLICATION: {
      return {
        ...state,
        application_id: action.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default applicationReducer;
