import React from "react";
import { Typography } from "@material-ui/core";

import NavGroup from "./NavGroup";
import menuItem from "./../../../../menu-items/main-menu-items";
import menuItemUser from "./../../../../menu-items/main-menu-items-user";

import { useSelector } from "react-redux";

const MenuList = () => {
  var isAdmin = false;
  const account = useSelector((state) => state.account);
  const roles = account.user.roles;
  roles.forEach((element) => {
    if (element === "ROLE_ADMIN") {
      isAdmin = true;
    }
  });
  if (isAdmin === true) {
    const navItems = menuItem.items.map((item) => {
      switch (item.type) {
        case "group":
          return <NavGroup key={item.id} item={item} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });
    return navItems;
  } else {
    const navItems = menuItemUser.items.map((item) => {
      switch (item.type) {
        case "group":
          return <NavGroup key={item.id} item={item} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });
    return navItems;
  }
};

export default MenuList;
