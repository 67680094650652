import React from "react";
import { FormattedMessage } from "react-intl";

import {
  IconAtom,
  IconBasket,
  IconBellRinging,
  IconBorderAll,
  IconBorderRadius,
  IconBoxMultiple,
  IconBrandChrome,
  IconBrandGravatar,
  IconBrush,
  IconBug,
  IconCalendar,
  IconChartArcs,
  IconChartCandle,
  IconChartInfographic,
  IconCircle,
  IconCircleOff,
  IconClipboardList,
  IconDashboard,
  IconDeviceAnalytics,
  IconFiles,
  IconForms,
  IconHelp,
  IconId,
  IconKey,
  IconLayoutList,
  IconLoader,
  IconLockAccess,
  IconMail,
  IconMenu,
  IconMessages,
  IconNfc,
  IconPalette,
  IconPencil,
  IconPhoneCall,
  IconPictureInPicture,
  IconReceipt2,
  IconRun,
  IconShadow,
  IconShape,
  IconShieldLock,
  IconSitemap,
  IconTools,
  IconTypography,
  IconUser,
  IconUserCheck,
  IconHistory,
  IconFileImport,
} from "@tabler/icons";

const icons = {
  IconDashboard: IconDashboard,
  IconDeviceAnalytics,

  IconChartArcs: IconChartArcs,
  IconClipboardList: IconClipboardList,
  IconChartInfographic: IconChartInfographic,
  IconHistory: IconHistory,
  IconForms: IconForms,
  IconFileImport: IconFileImport,
  IconReceipt2: IconReceipt2,
  IconPencil: IconPencil,
  IconPalette: IconPalette,
  IconShadow: IconShadow,
  IconPhoneCall: IconPhoneCall,
  IconBrandChrome: IconBrandChrome,
  IconFiles: IconFiles,
  IconAtom: IconAtom,
  IconTools: IconTools,
  IconBrush: IconBrush,
  IconLockAccess: IconLockAccess,
  IconShieldLock: IconShieldLock,
  IconKey: IconKey,
  IconTypography: IconTypography,
  IconMenu: IconMenu,
  IconBoxMultiple: IconBoxMultiple,
  IconCircleOff: IconCircleOff,
  IconCircle: IconCircle,
  IconBorderRadius: IconBorderRadius,
  IconBrandGravatar: IconBrandGravatar,
  IconShape: IconShape,
  IconUserCheck: IconUserCheck,
  IconId: IconId,
  IconLayoutList: IconLayoutList,
  IconBug: IconBug,
  IconLoader: IconLoader,
  IconRun: IconRun,
  IconUser: IconUser,
  IconHelp: IconHelp,
  IconSitemap: IconSitemap,
  IconPictureInPicture: IconPictureInPicture,
  IconMail: IconMail,
  IconMessages: IconMessages,
  IconNfc: IconNfc,
  IconCalendar: IconCalendar,
  IconBellRinging: IconBellRinging,
  IconBorderAll: IconBorderAll,
  IconChartCandle: IconChartCandle,
  IconBasket: IconBasket,
};

const menuItemsUser = {
  items: [
    {
      id: "dashboard",
      title: <FormattedMessage id="dashboard" />,
      type: "group",
      children: [
        {
          id: "dash-default",
          title: <FormattedMessage id="home" />,
          type: "item",
          url: "/dashboard/default",
          icon: icons["IconDashboard"],
          breadcrumbs: false,
        },
      ],
    },

    {
      id: "applications",
      title: <FormattedMessage id="applications" />,
      type: "group",
      children: [
        {
          id: "applications",
          title: <FormattedMessage id="Applications" />,
          type: "item",
          url: "/applications",
          icon: icons["IconFileImport"],
          breadcrumbs: false,
        },
        {
          id: "past-applications",
          title: <FormattedMessage id="Past applications" />,
          type: "item",
          url: "/dashboard/default",
          icon: icons["IconHistory"],
          breadcrumbs: false,
        },
      ],
    },

    {
      id: "sample-docs-roadmap",
      type: "group",
      children: [
        {
          id: "welcome-page",
          title: <FormattedMessage id="Notifications" />,
          type: "item",
          url: "/welcome-page",
          icon: icons["IconBrandChrome"],
        },
        {
          id: "documentation",
          title: <FormattedMessage id="Help" />,
          type: "item",
          url: "#",
          icon: icons["IconHelp"],
          target: true,
          external: true,
        },
      ],
    },
  ],
};

export default menuItemsUser;
