import React, { lazy } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import MainLayout from "./../layout/MainLayout";

const DashboardDefault = lazy(() => import("../views/dashboard/Default"));

const TableBasic = lazy(() => import("../views/forms/tables/TableBasic"));
const TableDense = lazy(() => import("../views/forms/tables/TableDense"));

const UtilsTypography = lazy(() => import("../views/utilities/typography"));
const UtilsColor = lazy(() => import("../views/utilities/color"));
const UtilsShadow = lazy(() => import("../views/utilities/shadow"));
const UtilsMaterialIcons = lazy(() =>
  import("../views/utilities/icons/MaterialIcons")
);
const UtilsTablerIcons = lazy(() =>
  import("../views/utilities/icons/TablerIcons")
);

const Welcome = lazy(() => import("../views/application/Welcome"));
const ApplicationStep1 = lazy(() => import("../views/application/Step1"));
const ApplicationStep2 = lazy(() => import("../views/application/Step2"));
const ApplicationStep3 = lazy(() => import("../views/application/Step3"));
const ApplicationStep4 = lazy(() => import("../views/application/Step4"));
const ApplicationStep5 = lazy(() => import("../views/application/Step5"));
const PaymentStart = lazy(() => import("../views/application/PaymentStart"));
const PaymentSuccess = lazy(() =>
  import("../views/application/PaymentSuccess")
);

const Applications = lazy(() =>
  import("../views/application/PendinApplicationTable")
);
const AdminApplications = lazy(() =>
  import("../views/admin/PendinApplicationTable")
);
const AdminApplicationsPast = lazy(() =>
  import("../views/admin/PastApplicationTable")
);
const AdminUsers = lazy(() => import("../views/admin/ApplicationUsersTable"));
const AdminEditApplication = lazy(() =>
  import("../views/admin/EditUserApplication")
);

const MainRoutes = () => {
  const location = useLocation();

  return (
    <Route
      path={[
        "/dashboard/default",

        "/tables/tbl-basic",
        "/tables/tbl-dense",

        "/utils/util-typography",
        "/utils/util-color",
        "/utils/util-shadow",
        "/icons/tabler-icons",
        "/icons/material-icons",
        "/welcome-page",
        "/welcome",
        "/application-step1",
        "/application-step2",
        "/application-step3",
        "/application-step4",
        "/application-step5",
        "/applications",
        "/admin-applications",
        "/admin-past-applications",
        "/admin-users",
        "/admin-edit-application",
        "/application-payment",
        "/application-payment-success",
      ]}
    >
      <MainLayout showBreadcrumb={true}>
        <Switch location={location} key={location.pathname}>
          <Route path="/dashboard/default" component={DashboardDefault} />
          <Route path="/tables/tbl-basic" component={TableBasic} />
          <Route path="/tables/tbl-dense" component={TableDense} />
          <Route path="/utils/util-typography" component={UtilsTypography} />
          <Route path="/utils/util-color" component={UtilsColor} />
          <Route path="/utils/util-shadow" component={UtilsShadow} />
          <Route path="/icons/tabler-icons" component={UtilsTablerIcons} />
          <Route path="/icons/material-icons" component={UtilsMaterialIcons} />
          <Route path="/welcome-page" component={Welcome} />
          <Route path="/welcome" component={Welcome} />

          <Route path="/application-step1" component={ApplicationStep1} />
          <Route path="/application-step2" component={ApplicationStep2} />
          <Route path="/application-step3" component={ApplicationStep3} />
          <Route path="/application-step4" component={ApplicationStep4} />
          <Route path="/application-step5" component={ApplicationStep5} />

          <Route path="/applications" component={Applications} />
          <Route path="/admin-applications" component={AdminApplications} />
          <Route path="/admin-past-applications" component={AdminApplicationsPast} />
          <Route path="/admin-users" component={AdminUsers} />
          <Route
            path="/admin-edit-application"
            component={AdminEditApplication}
          />
          <Route path="/application-payment" component={PaymentStart} />
          <Route
            path="/application-payment-success"
            component={PaymentSuccess}
          />
        </Switch>
      </MainLayout>
    </Route>
  );
};

export default MainRoutes;
