const config = {
  // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs,  like '/berry-material-react/react/default'
  basename: "",
  defaultPath: "/pages/login",
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  theme: "light",
  i18n: "en", // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
};

export default config;
